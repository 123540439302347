<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                RustCC分享会｜非凸科技与开发者共同探讨Rust安全进化
              </p>
              <div class="fst-italic mb-2">2023年10月23日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >技术交流</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/60/01-主题.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    10月15日，非凸科技受邀参加RustCC联合多家开发者社区组织的Global Tour of Rust技术分享活动，旨在为Rust开发者提供交流互动的平台，分享Rust语言的知识、经验和最佳实践。
                  </p>
                  <p>
                    活动上，非凸科技成都分公司研发总监赵海峰以“Rust安全进化”为主题，从编程语言的安全性、C/C++的安全性问题、Rust的解决方案以及Rust FFI四大角度进行了详细分享，并与现场观众展开了深度探讨。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/60/02-安全性.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    安全性是指编程语言在实现过程中避免出现错误、漏洞和缺陷的能力，包括内存安全、类型安全、数据安全等。对此，赵海峰表示，“它可以更加方便地帮助工程师们表达意图，不会产生过多的未定义行为，这就是编程语言本身的安全性。”非凸科技作为一家金融科技公司，使用Rust语言最主要的原因是：Rust比C++安全。C语言作为C++的前身，其不安全性体现在：手动管理内存容易造成内存泄漏和缓冲区溢出、指针操作容易造成非法访问和内存损坏、没有严格类型检查的函数调用容易造成函数参数错误和返回值错误、缺乏异常处理机制、资源管理难度高、软件缺陷难以调查等问题。虽然C++在安全性上有所提升，提供了引用、智能指针、RAII、异常、泛型、显式类型转换等等机制，但是这些提升并不是强制性的，需要靠工程师的经验和严谨来保证安全性。
                  </p>
                  <p>
                    那么，针对C/C++的不安全问题，Rust有怎样的解决方案呢？Rust通过所有权系统来管理内存、借用检查器来检查对对象的访问、通过生命周期来标识资源的作用域，以及通过Option、Result等设施来处理错误和异常。赵海峰表示，“Unsafe不是给Rust的安全性开一个口子，而是让Rust更加安全，它告诉调用者有更多的contracts。”Rust Unsafe中的unsafe block、unsafe function以及unsafe traits，可以使用标准库的unsafe函数，操作原始指针、union和mutable static variables，还可以通过FFI调用其他语言的代码。除此之外，Rust的其他安全检查仍然有效，比如类型检查、生命周期检查、索引检查等。“将一个block标记为unsafe，是为了明确告诉使用者，它有其他的contracts需要满足，否则就可能出现未定义行为。所以，一定要注意这些约束，写出的代码才会更安全。”赵海峰补充道。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/60/03-代码.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    当前，券商、私募等金融机构提供的接口（SDK）基本是C++语言，很多金融公司也会基于业务需求而去选择C++作为开发语言。赵海峰表示，“FFI是Rust与C/C++之间的桥梁，允许Rust代码调用C/C++库，反之亦然。”FFI允许Rust与C/C++代码之间进行高效和简洁的交互，FFI支持多种操作系统和硬件平台，使得Rust与C/C++代码可以在各种环境中运行，通过bindgen 或autocratic等crates可以方便地自动生成C/C++到Rust的bindings代码。除此之外，赵海峰还通过代码案例，详细阐述了异步回调、跨越语言边界的unwind等在使用FFI时的安全化处理。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/60/04-现场.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    交流环节，大家结合自身Rust代码实践情况，分享了诸多开发经验和技术挑战，现场讨论气氛活跃又热烈。未来，非凸科技希望携手更多Rust开发者、爱好者，共同探索Rust世界及其不断发展的生态系统。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News60",
};
</script>

<style></style>
